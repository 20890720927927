const onlyDigitalAsString = (value) => value.replace(/\D/g, "");

const onlyMonetaryAsString = (value) => {
  value = value.replace(/[^0-9,.]*/g, "");
  value = value.replace(/^[0]{2,}/g, "0");
  value = value.replace(/^[0]+[0-9]{1,}/g, value.substring(1, value.length));
  value = value.replace(/,/g, ".");
  value = value.replace(/^[.]/g, "0.");
  value = value.replace(/\.{2,}/g, ".");
  value = value.replace(/^[0-9]+[.]+[0-9]+[.]/g, value.substring(0, value.length - 1));
  value = isNaN(value) || value < 0 || !value ? "0" : value;

  const dotIndexOf = value.indexOf(".");
  const dotLastIndexOf = value.lastIndexOf(".");

  if (dotIndexOf !== -1 && dotIndexOf === dotLastIndexOf) {
    value = value.replace(/\d+[.]+\d{3,}/g, value.substring(0, dotIndexOf + 3));
  }

  if (dotIndexOf !== -1 && dotLastIndexOf !== -1 && dotIndexOf !== dotLastIndexOf) {
    value = value.substr(0, dotLastIndexOf);
  }

  return value;
};

export { onlyDigitalAsString, onlyMonetaryAsString };
