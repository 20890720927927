import { Fade } from "react-awesome-reveal";

const PaymentFinished = () => {
  return (
    <div className="w-full flex flex-col xl:flex-row justify-between items-center px-5 md:px-28 lg:px-64">
      <div className="w-full my-5">
        <Fade triggerOnce>
          <p className="text-gray text-lg md:text-2xl text-center uppercase pb-5">
            Dziękujemy za przekazany <span className="font-semibold">napiwek</span> {":)"}
          </p>
        </Fade>
      </div>
    </div>
  );
};

export default PaymentFinished;
