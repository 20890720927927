const CardPlace = ({ image = "", left = true, label = "W restauracji", description = "Cena sesji obejmuje: - voucher ważny przez 12 miesięcy - galerie online do wyboru zdjęć - 25 odbitek 15 x 23 cm - 25 obrobionych zdjęć - album eko" }) => {
  return (
    <div className={`my-10 flex flex-col ${left ? "md:flex-row" : "md:flex-row-reverse"} justify-center items-center`}>
      {image && <img src={image} className="w-auto md:w-1/2" alt="place" />}
      <div className="md:w-1/2 flex flex-col items-center">
        <p className="text-center text-lg text-gray font-bold uppercase">{label}</p>
        <div className="bg-orange-light w-8 h-8   rounded-full my-3"></div>
        <p className="text-left text-gray font-light">{description}</p>
      </div>
    </div>
  );
};

export default CardPlace;
