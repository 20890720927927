import { Fade } from "react-awesome-reveal";

const InformationClause = () => {
  return (
    <Fade triggerOnce>
      <div className="w-full flex flex-col px-5 py-5 md:py-10 md:px-28 lg:px-64">
        <p className="text-center font-thin">
          <p className="pb-5">
            <p className="font-normal">
              <strong>Klauzula informacyjna dla odwiedzaj&#261;cych Fanpage ShakeTips na Facebooku</strong>
            </p>
            <br />
            Zgodnie z art. 13 i 14 rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej Rozporządzenie), informujemy że: <br />
            I. ADMINISTRATOR DANYCH OSOBOWYCH
          </p>
          <p className="pb-5">
            Administratorem fanpage ShakeTips jest Honey Payment spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie, przy ul. Nowy Świat 33/13, 00-029 Warszawa, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000895826, kapitał zakładowy w wysokości 5.000,00 zł wpłacony w całości, posiadająca numer NIP 5252859204, REGON 388760204.
            <br />
            Zgodnie z orzecznictwem Trybunału Sprawiedliwości Unii Europejskiej twórca fanpage na Facebooku jest Współadministratorem danych osobowych wraz z Facebookiem.
          </p>

          <p className="pb-5">
            Wobec tego Współadministratorem Państwa danych osobowych jest Honey Payment spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie, przy ul. Nowy Świat 33/13, 00-029 Warszawa, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000895826, kapitał zakładowy w wysokości 5.000,00 zł wpłacony w całości, posiadająca numer NIP 5252859204, REGON 388760204, adres e-mail hello@dotbee.app (dalej także „Administrator”).
          </p>

          <p className="pb-5">
            Honey Payment sp. z o.o. zaleca zapoznanie się ze szczegółami dotyczącymi przetwarzania Państwa danych przez serwis Facebook https://www.facebook.com/privacy/explanation.
          </p>

          <p className="pb-5">II. INSPEKTOR OCHRONY DANYCH OSOBOWYCH</p>

          <p className="pb-5">
            Honey Payment Sp. z o. o. wyznaczyła Inspektora Ochrony Danych Osobowych, z którym można skontaktować się w następujący sposób: <br />
            1) przesy&#322;aj&#261;c wiadomo&#347;&#263; na adres e-mail: iodo@honeypayment.pl, <br />
            2) przesyłając wiadomość przesyłką poleconą na adres Honey Payment sp. z o.o. z siedzibą w Warszawie przy ulicy Nowy Świat 33/13, 00-029 Warszawa.
            <br />
            III. CEL ORAZ PODSTAWA PRAWNA PRZETWARZANIA DANYCH OSOBOWYCH
          </p>

          <p className="pb-5">
            {" "}
            1. Pa&#324;stwa dane osobowe b&#281;d&#261; przetwarzane w nast&#281;puj&#261;cych celach:
            <br />
            1) prowadzenia komunikacji w ramach oficjalnego fanpage Administratora o nazwie ShakeTips, w szczególności odpowiedzi na reakcje, komentarze oraz wiadomości prywatne (podstawa prawna: art. 6 ust. 1 lit. f) Rozporządzenia);
            <br />
            2) obsługi zapytania wysłanego za pośrednictwem formularza kontaktowego znajdującego się na oficjalnym profilu Administratora w portalu społecznościowym Facebook (podstawa prawna: art. 6 ust. 1 lit. f) Rozporządzenia);
            <br />
            3) statystycznych oraz reklamowych realizowanych za pośrednictwem narzędzi dostarczanych przez aplikację serwisu społecznościowego Facebook (podstawa prawna: art. 6 ust. 1 lit. f) Rozporządzenia);
            <br />
            4) w przypadku, gdyby przetwarzanie Państwa danych osobowych miało nastąpić w innym celu niż wskazane powyżej, każdorazowo odbywać będzie się po uprzednim uzyskaniu Państwa zgody na przetwarzanie danych osobowych, w zakresie i celu wskazanym w tej zgodzie (podstawa z art. 6 ust. 1 lit. a) Rozporządzenia).
            <br />
            2. Prawnie uzasadnionym interesem Administratora, na którego podstawie odbywa się przetwarzanie Państwa danych osobowych jest zapewnienie ciągłości komunikacji biznesowej, marketing produktów oraz usług, a także dbanie o wizerunek Administratora.
          </p>

          <p className="pb-5">
            {" "}
            IV. KATEGORIE PRZETWARZANYCH DANYCH OSOBOWYCH
            <br />
            Administrator danych osobowych przetwarza nast&#281;puj&#261;ce dane osobowe:
            <br />
            1) dane identyfikacyjne (zazwyczaj imi&#281; i nazwisko) w zakresie opublikowanym przez Pa&#324;stwa na
            Pa&#324;stwa profilu na portalu spo&#322;eczno&#347;ciowym Facebook,
            <br />
            2) wizerunek (zdj&#281;cie profilowe oraz inne zdj&#281;cia widoczne dla ka&#380;dego u&#380;ytkownika),
            <br />
            3) dane przekazane w wiadomo&#347;ci prywatnej na Messengerze,
            <br />
            4) dane przekazane w formularzu kontaktowym,
            <br />
            5) wszystkie inne informacje dost&#281;pne publicznie w ramach Pa&#324;stwa profilu na Facebook.
          </p>

          <p className="pb-5">
            {" "}
            V. &#377;R&Oacute;D&#321;O POCHODZENIA DANYCH OSOBOWYCH
            <br />
            Państwa dane osobowe są zbierane przez Administratora w przypadku kiedy obserwują Państwo fanpage ShakeTips, gdy opublikują Państwo komentarz pod którymkolwiek z postów lub wchodzą Państwo w interakcję z treściami opublikowanymi na profilu ShakeTips na Facebooku. Państwa dane są także zbierane w przypadku, gdy kierują Państwo wiadomości prywatne za pośrednictwem Messengera do Administratora oraz w przypadku podania przez Państwa danych osobowych w formularzu kontaktowym znajdującym się na oficjalnym fanpage Administratora.
            <br />
            VI. ODBIORCY DANYCH OSOBOWYCH
          </p>

          <p className="pb-5">
            {" "}
            1. Pa&#324;stwa dane osobowe mog&#261; zosta&#263; udost&#281;pnione nast&#281;puj&#261;cym kategoriom
            podmiot&oacute;w:
            <br />
            1) instytucjom lub podmiotom trzecim uprawnionym do &#380;&#261;dania dost&#281;pu lub otrzymania danych
            osobowych na podstawie przepis&oacute;w prawa;
            <br />
            2) innym podmiotom, kt&oacute;re na podstawie stosownych um&oacute;w o powierzeniu przetwarzania danych
            podpisanych z Administratorem przetwarzaj&#261; dane osobowe w imieniu Administratora np. podmiotom
            zajmuj&#261;cym si&#281; utrzymaniem system&oacute;w informatycznych oraz serwis&oacute;w internetowych oraz
            podmiotom wspieraj&#261;cym dzia&#322;ania marketingowe Administratora,
            <br />
            3) w&#322;a&#347;cicielowi portalu spo&#322;eczno&#347;ciowego Facebook na niepodlegaj&#261;cych zmianie
            zasadach dotycz&#261;cych danych okre&#347;lonych przez Facebook dost&#281;pnych pod adresem
            https://www.facebook.com/about/privacy.
          </p>

          <p className="pb-5">
            {" "}
            2. Administrator nie przekazuje Pa&#324;stwa danych osobowych, do pa&#324;stwa trzeciego lub organizacji
            mi&#281;dzynarodowej, jednak z uwagi na ponadnarodowy charakter przep&#322;ywu danych w ramach serwisu
            Facebook Pa&#324;stwa dane osobowe mog&#261; zosta&#263; przekazane przez serwis Facebook poza teren
            Europejskiego Obszaru Gospodarczego. Wskaza&#263; nale&#380;y, i&#380; w&#322;a&#347;ciciel serwisu Facebook
            deklaruje wykorzystywanie typowych klauzul umownych zatwierdzonych przez Komisj&#281; Europejsk&#261; i
            opieranie si&#281; na decyzjach Komisji Europejskiej stwierdzaj&#261;cych odpowiedni stopie&#324; ochrony
            danych w odniesieniu do okre&#347;lonych kraj&oacute;w &ndash; wi&#281;cej:
            https://www.facebook.com/privacy/explanation.
          </p>

          <p className="pb-5"> VII. OKRES PRZETWARZANIA DANYCH OSOBOWYCH</p>

          <p className="pb-5">
            Pa&#324;stwa dane osobowe b&#281;d&#261; przetwarzane do czasu wyra&#380;enia sprzeciwu lub ustania
            cel&oacute;w przetwarzania. W przypadku, gdy Pa&#324;stwa dane osobowe s&#261; przetwarzane na podstawie
            wyra&#380;onej przez Pa&#324;stwa zgody b&#281;d&#261; one przetwarzane do czasu wycofania zgody.
          </p>

          <p className="pb-5"> VIII. PRAWA PRZYS&#321;UGUJ&#260;CE W ZWI&#260;ZKU Z PRZETWARZANIEM DANYCH OSOBOWYCH</p>

          <p className="pb-5">
            {" "}
            1. W zwi&#261;zku z przetwarzaniem przez Administratora Pa&#324;stwa danych osobowych przys&#322;uguj&#261;
            Pa&#324;stwu nast&#281;puj&#261;ce prawa:
            <br />
            1) prawo dost&#281;pu do tre&#347;ci udost&#281;pnionych danych osobowych,
            <br />
            2) prawo do sprostowania danych osobowych,
            <br />
            3) prawo do usuni&#281;cia danych osobowych lub ograniczenia ich przetwarzania,
            <br />
            4) prawo wniesienia sprzeciwu, co do przetwarzania danych osobowych,
            <br />
            5) prawo do przenoszenia danych,
            <br />
            6) prawo do niewyra&#380;enia zgody na marketing bezpo&#347;redni.
            <br />
            2. Powy&#380;sze prawa mog&#261; Pa&#324;stwo zrealizowa&#263; poprzez: <br />
            1) przesłanie wiadomości na adres email: info@honeypayment.pl,
            <br />
            2) kontakt telefoniczny z Biurem Obsługi Klienta Honey Payment: 32 700 81 66,
            <br />
            3) przesłanie wiadomości listem poleconym na adres: Honey Payment sp. z o.o., 00-029 Warszawa, ul. Nowy Świat 33/13, <br />
            4) kontaktując się z powołanym w Honey Payment Inspektorem Ochrony Danych Osobowych pod adresem email: iodo@honeypayment.pl
            3. W przypadku, gdy przetwarzanie przez Honey Payment Sp. z o.o. danych osobowych odbywa się na podstawie udzielonej przez Państwa zgody, mają Państwo prawo do wycofania takiej zgody wdowolnym momencie. Wycofanie zgody nie będzie wpływało na zgodność z prawem przetwarzania Państwa danych osobowych, których dokonano na podstawie Państwa zgody przed jej wycofaniem.
          </p>

          <p className="pb-5"> IX. PRAWO WNIESIENIA SKARGI DO ORGANU NADZORCZEGO</p>

          <p className="pb-5">
            W przypadku niezgodnego z prawem przetwarzania danych osobowych przez Administratora przys&#322;uguje
            Pa&#324;stwo prawo do wniesienia skargi do organu nadzorczego, tj. Prezesa Urz&#281;du Ochrony Danych
            Osobowych.
          </p>

          <p className="pb-5"> X. ZAUTOMATYZOWANE PODEJMOWANIE DECYZJI, PROFILOWANIE</p>

          <p className="pb-5">
            Pa&#324;stwa dane osobowe nie b&#281;d&#261; przetwarzane przez Administratora w celu zautomatyzowanego
            podejmowania decyzji, w tym dane nie b&#281;d&#261; profilowane.
          </p>

          <p className="pb-5"> XI. INFORMACJA O WYMOGU/DOBROWOLNO&#346;CI PODANIA DANYCH OSOBOWYCH</p>

          <p className="pb-5">
            Pa&#324;stwa dane osobowe s&#261; przetwarzane na zasadzie dobrowolno&#347;ci ich podania jednak
            konsekwencj&#261; nie podania danych b&#281;dzie brak mo&#380;liwo&#347;ci przegl&#261;dania Fanpage
            ShakeTips lub komunikacji za po&#347;rednictwem dost&#281;pnych funkcjonalno&#347;ci serwisu Facebook,
            takich jak komentarze oraz prywatne wiadomo&#347;ci.
          </p>

          <p className="pb-5"></p>
        </p>
      </div>
    </Fade>
  );
};

export default InformationClause;
