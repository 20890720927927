const Button = ({ label = "", isDisabled = false, onClick = () => {}, className = "", ...props }) => {
  return (
    <div className="my-5 w-full flex justify-center">
      <button onClick={onClick} disabled={isDisabled} className={`text-white rounded-full py-2 px-8 ${isDisabled ? "bg-gray-light cursor-not-allowed" : "bg-orange hover:opacity-75"} transition-all` + className} {...props}>
        {label}
      </button>
    </div>
  );
};

export default Button;
