import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

const MyCheckbox = withStyles({
  root: {
    color: "#FF6A13",
    "&$checked": {
      color: "#FF6A13",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CustomCheckbox = ({ ...props }) => {
  return <MyCheckbox {...props} />;
};

export default CustomCheckbox;
