import { Fade } from "react-awesome-reveal";

const Contact = () => {
  return (
    <Fade triggerOnce>
      <div className="w-full flex flex-col px-5 py-5 md:py-10 md:px-28 lg:px-64">
        <p className="text-gray text-center lg:text-left text-2xl md:text-3xl lg:text-4xl tracking-widest uppercase pb-10">
          Kontakt
        </p>
        <div className="flex flex-col lg:flex-row">
        {/* <div className="flex-1 pb-8 text-center lg:text-left lg:pr-8"></div> */}
          <div className="flex-1 pb-8 text-center lg:text-left lg:pr-8 font-semibold">
            <p>Honey Payment Sp. z o.o.</p>
            <p>ul. NOWY ŚWIAT, nr 33, lok. 13,</p>
            <p>00-029 WARSZAWA</p>
          </div>
          <div className="flex-1 pb-8 text-center lg:text-left lg:pr-8 font-semibold">
            <p className="underline hover:opacity-75">
              <a href="tel:+48 32 700 81 66">+48 32 700 81 66</a>
            </p>
            <p className="underline hover:opacity-75">
              <a href="mailto:info@shaketips.pl">info@shaketips.pl</a>
            </p>
          </div>
          <div className="flex-1 pb-8 text-center lg:text-left lg:pr-8 font-semibold">
            <p>Infolinia czynna </p>
            <p className="font-light">od 8 do 16 pn-pt</p>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default Contact;
