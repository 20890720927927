import { APIes, APINames } from "../consts/API";

const METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

const remove = (data) => fetchData({ method: METHODS.DELETE, ...data });

const put = (data) => fetchData({ method: METHODS.PUT, ...data });

const post = (data) => fetchData({ method: METHODS.POST, ...data });

const get = (data) => fetchData({ method: METHODS.GET, ...data });

const fetchData = ({ api, path, method = METHODS.GET, body, headers = {}, onFinish = () => {}, onError = () => {} }) => {
  // console.log("endpoint :>> ", api + path);
  // console.log("request :>> ", JSON.stringify(body));
  fetch(api + path, {
    method,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    ...(body ? { body: JSON.stringify(body) } : {}),
  })
    .then(async (res) => {
      const data = await res.json();

      return { ...data, status: res.status };
    })
    .then((data) => {
      // console.log("response :>> ", data);
      onFinish(data);
    })
    .catch((data) => onError(data));
};

export { APINames, METHODS };

const DataSource = (apiName) => {
  let api = APIes[apiName];

  return {
    get: (data) => get({ api, ...data }),
    post: (data) => post({ api, ...data }),
    put: (data) => put({ api, ...data }),
    remove: (data) => remove({ api, ...data }),
  };
};

export default DataSource;
