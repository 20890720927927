import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import Home from "../pages/Home";
import ForEnterprise from "../pages/ForEnterprise";
import Contact from "../pages/Contact";
import Affi from "../pages/Affi";
import MakePayment from "../pages/MakePayment";
import PaymentFinished from "../pages/PaymentFinished";
import InformationClause from "../pages/InformationClause";
import ROUTES from "../consts/ROUTES";
import {parse} from "query-string";

const RouterSwitch = () => {
    const location = useLocation();

    if (location.pathname==ROUTES.MAKE_PAYMENT_DEPRECATED){
        const parsedUrl = parse(location.search);
        var {short_code} = parsedUrl;
    }else{
        var {short_code} = '';
    }

    return (
    <Switch>
      <Route path={ROUTES.CONTACT}>
        <Contact />
      </Route>
      <Route path={ROUTES.ENTERPRISE}>
        <ForEnterprise />
      </Route>
      <Route path={ROUTES.MAKE_PAYMENT_DEPRECATED}>
        <Redirect to={ROUTES.MAKE_PAYMENT + '/' + short_code} />
      </Route>
      <Route path={ROUTES.MAKE_PAYMENT}>
        <MakePayment />
      </Route>
      <Route path={ROUTES.PAYMENT_FINISHED_DEPRECATED}>
        <Redirect to={ROUTES.PAYMENT_FINISHED} />
      </Route>
      <Route path={ROUTES.PAYMENT_FINISHED}>
        <PaymentFinished />
      </Route>
      <Route path={ROUTES.INFORMATION_CLAUSE}>
        <InformationClause />
      </Route>
        <Route path={ROUTES.AFFI}>
            <Affi />
        </Route>
      <Route path={ROUTES.HOME}>
        <Home />
      </Route>
    </Switch>
  );
};

export default RouterSwitch;
