import ROUTES from "./ROUTES";
import {NavLink} from "react-router-dom";

const NAV_LINKS = [
    (
        <NavLink exact className="px-2 text-gray hover:text-orange transition-all"
                 activeClassName="text-orange font-bold" to={ROUTES.HOME}>
            Home
        </NavLink>
    ),
    (
        <a className="px-2 text-gray hover:text-orange transition-all"
           href="./#jak-to-dziala">
            Jak to działa
        </a>
    ),
    (
        <NavLink className="px-2 text-gray hover:text-orange transition-all"
                 activeClassName="text-orange font-bold" to={ROUTES.ENTERPRISE}>
            Dla firm
        </NavLink>
    ),
    (
        <NavLink className="px-2 text-gray hover:text-orange transition-all"
                 activeClassName="text-orange font-bold" to={ROUTES.CONTACT}>
            Kontakt
        </NavLink>
    )
];

export default NAV_LINKS;
