import { BrowserRouter as Router } from "react-router-dom";

import Navbar from "./components/Utility/Navbar";
import Footer from "./components/Utility/Footer";
import Container from "./components/Utility/Container";
import RouterSwitch from "./router/RouterSwitch";

function App() {
  return (
    <div className="flex flex-col min-h-screen justify-between">
      <Router>
        <Navbar />
        <Container>
          <RouterSwitch />
        </Container>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
