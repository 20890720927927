import { NavLink, Link } from "react-router-dom";
import { useLocation } from "react-router";

import polityka from "../../documents/Polityka_Prywatności_Shake_Tips.pdf";
import regulamin from "../../documents/Regulamin_Shake_Tips.pdf";

import ROUTES from "../../consts/ROUTES";
import logo from "../../assets/svg/logo.svg";
import arrow from "../../assets/svg/arrow.svg";
import knf_logo from "../../assets/img/knf-logo.png";
import dpay_logo from "../../assets/img/dpay-logo.jpeg";
import logo_dotbee from "../../assets/img/logo_dotbee.png";

const Footer = () => {
  const location = useLocation();
  const isMakePayment = location.pathname === ROUTES.MAKE_PAYMENT;

  const scrollToTopHandler = () =>
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

  return (
    <footer className="py-5 md:px-16 lg:px-52 h-auto w-full bg-orange-light">
      <div className="container mx-auto flex flex-col items-center justify-center">
        <div className="w-full flex flex-col md:flex-row justify-between">
          <div className="flex flex-col items-center justify-center pb-5 w-full md:w-1/3">
            <Link className="w-2/3 md:w-2/4 h-auto pb-3" to={ROUTES.HOME}>
              <img src={logo} alt="logo" />
            </Link>
            {isMakePayment && (
              <>
                <div className="flex flex-row justify-between w-full px-6 items-center my-2">
                  <img src={knf_logo} className="w-1/4 mx-1 h-auto" alt="Logo KNF" />
                  <img src={logo_dotbee} className="w-1/4 mx-1 h-auto" alt="Logo Provema" />
                  <img src={dpay_logo} className="w-1/4 mx-1 h-auto" alt="Logo dPay" />
                </div>
              </>
            )}
            <p className="text-gray text-center ">Honey Payment Sp. z o.o.</p>
            <p className="text-gray text-center ">ul. NOWY ŚWIAT, nr 33, lok. 13, 00-029 WARSZAWA</p>
          </div>
          <div className="flex flex-row justify-between md:justify-center px-5 py-3 md:py-10 md:w-1/3">
            <div className="flex flex-col justify-center">
              <a className="px-2 text-orange transition-all" href={polityka}>
                Polityka prywatności
              </a>
              <a className="px-2 text-orange transition-all" href={regulamin}>
                Regulamin
              </a>
              <NavLink className="px-2 text-orange transition-all" to={ROUTES.INFORMATION_CLAUSE}>
                Klauzula Informacyjna - Fanpage
              </NavLink>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between md:justify-center px-5 py-3 md:py-10 md:w-1/3">
            <div className="flex flex-col pr-5">
              <NavLink exact className="px-2 text-gray hover:text-orange" to={ROUTES.HOME}>
                Home
              </NavLink>
              <a className="px-2 text-gray hover:text-orange transition-all" href="./#jak-to-dziala">
                Jak to działa
              </a>
              <NavLink className="px-2 text-gray hover:text-orange transition-all" to={ROUTES.ENTERPRISE}>
                Dla firm
              </NavLink>
              <NavLink className="px-2 text-gray hover:text-orange transition-all" to={ROUTES.CONTACT}>
                Kontakt
              </NavLink>
            </div>
            <button onClick={scrollToTopHandler}>
              <img src={arrow} className="h-10 w-10 hover:opacity-75" alt="arrow" />
            </button>
          </div>
        </div>
        {/* <p className="text-gray ">
          © 2021 | Made with passion by{" "}
          <a href="https://provema.com/" className="text-orange" rel="noreferrer" target="_blank">
            Provema
          </a>
        </p> */}
      </div>
    </footer>
  );
};

export default Footer;
