import { styled } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

const MyTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#FF6A13",
  },
  "& .MuiInputBase-root": {
    backgroundColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#FF6A13",
    },
  },
});

const CustomTextField = ({ className = "", ...props }) => {
  return (
    <div className={className + " my-5"}>
      <MyTextField variant="outlined" className={"w-full"} {...props} />
    </div>
  );
};

export default CustomTextField;
