export const APIes = {
  tipTip: "https://api.shaketips.pl/api/v1",
  Wallet: "https://walletapi-dev.apipv.devpv.pl/api",
  IP: "https://ipapi.co",
};

export const APINames = {
  TIP_TIP: "tipTip",
  WALLET: "Wallet",
  IP: "IP",
};
