import { Fade } from "react-awesome-reveal";
import DataSource, {APINames} from "../logic/DataSource";
import {useEffect, useState} from "react";
import ROUTES from "../consts/ROUTES";
import {Redirect,useParams} from "react-router-dom";

const dataSource = DataSource(APINames.WALLET);
const dataSourceIP = DataSource(APINames.IP);


const Affi = () => {
    const { affi } = useParams();

    const getIPAddress = () => {
        dataSourceIP.get({
            path: "/json",
            onFinish: (res) => {
                sendAffi(res.ip);
            },
            onError: (err) => {
            },
        });
    };

    const sendAffi = (ip) => {
        dataSource.post({
            path: "/service/affiliates/register-movement-action",
            body: {
                type: 'click',
                ip : ip,
                row_id : 0,
                user_id : 0,
                force : true,
                token : affi,
                client_id : '',
                referrer : '',
            },
            headers: {
                "Authorization" : "Basic ZDgyNjk0NWEtM2JhZS0xMWVhLWI3N2YtMmU3MjhjZTg4MTI1OmUxMWIwYjQwLTNiYWUtMTFlYS1iNzdmLTJlNzI4Y2U4ODEyNQ==",
            },
            onFinish: (res) => {
            },
            onError: (err) => {
            },
        });
    };

    useEffect(() => {
        getIPAddress();
    },[]);

    return <Redirect to={ROUTES.HOME} />
};

export default Affi;
