import {useEffect, useState} from "react";
import {Fade} from "react-awesome-reveal";
import {InputAdornment, FormControlLabel} from "@material-ui/core";

import {APIes, APINames} from "../../consts/API";
import CustomTextField from "../../components/Utility/TextField";
import CustomButton from "../../components/Utility/Button";
import CustomCheckbox from "../../components/Utility/Checkbox";
import AmountTile from "../../components/MakePayment/AmountTile";
import regulamin from "../../documents/Regulamin_Shake_Tips.pdf";
import {onlyMonetaryAsString} from "../../logic/Common";
import PAYMENT_TYPES from "../../consts/PAYMENT_TYPES";
import {DEFAULT_PAYMENT_TYPE} from "../../consts/PAYMENT_TYPES";
import ROUTES from "../../consts/ROUTES";
import STORE_LINKS from "../../consts/STORE_LINKS";
import DataSource from "../../logic/DataSource";

const OPEN_STORE_LINK_DELAY = 3000;

const UserCode = ({userPlatform, companyName, codeUuid, codeName}) => {
    const dataSource = DataSource(APINames.TIP_TIP);
    const short_code = window.location.pathname.split("/")[2] || null;

    const [tipAmount, setTipAmount] = useState("10");
    const [tipAmountError, setTipAmountError] = useState("");
    const [customAmountOn, setCustomAmountOn] = useState(false);
    const [isAgreementSet, setIsAgreementSet] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const isAmountValid = parseFloat(tipAmount) > 0;
    const isProvemaValid = isAmountValid && isAgreementSet;
    const isDPayValid = isAmountValid && isAgreementSet;
    const isPaymenterioValid = isAmountValid && isAgreementSet;

    const setTipAmountHandler = (value) => {
        value = onlyMonetaryAsString(value);

        setTipAmount(value);
    };

    const setTipAmountByTileHandler = (value) => {
        setCustomAmountOn(false);

        value = onlyMonetaryAsString(value);
        setTipAmount(value);
    };

    const onOtherAmountTileHandler = () => {
        setTipAmount("0");
        setCustomAmountOn(true);
    };

    const validateFields = (callback = () => {
    }, isDPay = false) => {
        const parsedAmount = parseFloat(tipAmount);

        if (parsedAmount > 1000) setTipAmountError("Napiwek nie może być większy, niż 1000 PLN");

        if (isDPay && parsedAmount < 2) setTipAmountError("Napiwek nie może być mniejszy, niż 2 PLN");

        const isError = tipAmountError;

        return isError ? null : callback();
    };

    const onDPayPressHandler = () => {
        setIsLoading(true);
        dataSource.post({
            path: "/tip/handle-payment",
            body: {
                payment_gateway_type: PAYMENT_TYPES.DPAY,
                amount: parseFloat(tipAmount),
                code_uuid: codeUuid,
            },
            onFinish: (res) => {
                setIsLoading(false);
                res.data && window.open(res.data, "_self");
            },
            onError: () => {
                setIsLoading(false);
            },
        });
    };

    const onPaymenterioPressHandler = () => {
        setIsLoading(true);
        dataSource.post({
            path: "/tip/handle-payment",
            body: {
                payment_gateway_type: PAYMENT_TYPES.PAYMENTERIO,
                amount: parseFloat(tipAmount),
                code_uuid: codeUuid,
            },
            onFinish: (res) => {
                setIsLoading(false);
                res.data && window.open(res.data, "_self");
            },
            onError: () => {
                setIsLoading(false);
            },
        });
    };

    useEffect(() => {
        setTipAmountError("");
    }, [tipAmount]);

    const onProvemaPressHandler = () => {
        setIsLoading(true);

        dataSource.post({
            path: "/tip/handle-payment",
            body: {
                payment_gateway_type: PAYMENT_TYPES.PROVEMA,
                amount: parseFloat(tipAmount),
                code_uuid: codeUuid,
            },
            onFinish: (res) => {
                setIsLoading(false);
                if (res.data) {
                    window.location.replace(
                        `honeypayment://dotbee/shaketips&operation_uuid=${res.data.operation_uuid}&title=${res.data.title}&amount_operation=${res.data.amount_operation}&recipent=${companyName}`
                    );
                    setTimeout(() => {
                        if (
                            userPlatform &&
                            (window.location.href.includes(ROUTES.MAKE_PAYMENT_DEPRECATED) ||
                                window.location.href.includes(ROUTES.MAKE_PAYMENT))
                        )
                            window.location.replace(STORE_LINKS[userPlatform]);
                    }, OPEN_STORE_LINK_DELAY);
                }
            },
            onError: () => {
                setIsLoading(false);
            },
        });
    };

    return (
        <>
            <p className="text-gray text-lg lg:text-xl uppercase text-center mb-8">
                PODZIĘKUJ ZA MIŁĄ OBSŁUGĘ <span className="font-semibold">BEZGOTÓWKOWYM</span> NAPIWKIEM {":)"}
            </p>
            <div className="bg-orange-light py-5 px-3 mb-5 flex flex-col items-center">
                <p className="text-gray text-center pb-2">Dla</p>
                <img
                    src={`${APIes.tipTip}/codes/${short_code}/get-image-by-code`}
                    className="rounded pb-2 w-2/3"
                    alt="tip-receiver"
                />
                <p className="text-gray text-center pb-2">{companyName}</p>
                <p className="text-gray text-center pb-2">{codeName}</p>
            </div>
            <div className="lg:px-10">
                <div className="h-px w-full bg-gray-light mb-5"></div>
                <p className="text-gray text-center text-lg lg:text-xl mb-5">Wybierz kwotę napiwku:</p>
                <div className="mb-5 justify-evenly flex flex-row w-full flex-wrap">
                    <AmountTile
                        amount="5 PLN"
                        isActive={tipAmount === "5" && !customAmountOn}
                        onClick={() => setTipAmountByTileHandler("5")}
                    />
                    <AmountTile
                        amount="10 PLN"
                        isActive={tipAmount === "10" && !customAmountOn}
                        onClick={() => setTipAmountByTileHandler("10")}
                    />
                    <AmountTile
                        amount="15 PLN"
                        isActive={tipAmount === "15" && !customAmountOn}
                        onClick={() => setTipAmountByTileHandler("15")}
                    />
                    <AmountTile
                        amount="20 PLN"
                        isActive={tipAmount === "20" && !customAmountOn}
                        onClick={() => setTipAmountByTileHandler("20")}
                    />
                    <AmountTile amount="inna" isActive={customAmountOn} onClick={onOtherAmountTileHandler}/>
                </div>
                {customAmountOn && (
                    <Fade>
                        <div
                            className="px-3 mb-5 bg-orange-light rounded-2xl border border-gray-light border-dashed justify-center items-center align-middle flex flex-col lg:flex-row w-full">
                            <p className="pt-5 lg:pt-0 text-gray pr-5">Kwota napiwku: </p>
                            <CustomTextField
                                disabled={isLoading}
                                error={!!tipAmountError}
                                helperText={tipAmountError}
                                id="amount"
                                label="Kwota"
                                className="w-full"
                                value={tipAmount}
                                onChange={(e) => setTipAmountHandler(e.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
                                }}
                                inputProps={{maxLength: 10}}
                            />
                        </div>
                    </Fade>
                )}
                <div className="h-px w-full bg-gray-light mb-5"></div>
                <p className="text-gray text-center text-lg lg:text-xl">Zapłać</p>
                <FormControlLabel
                    control={
                        <CustomCheckbox
                            checked={isAgreementSet}
                            onChange={(event) => setIsAgreementSet(event.target.checked)}
                        />
                    }
                    label={
                        <p className="text-gray font-display text-sm font-light">
                            Akceptuję{" "}
                            <a className="text-orange transition-all" href={regulamin} target="_blank" rel="noreferrer">
                                Regulamin ShakeTips
                            </a>{" "}
                            .
                        </p>
                    }
                />
                <div className="flex flex-col md:flex-row w-full justify-between ">
                    {DEFAULT_PAYMENT_TYPE === PAYMENT_TYPES.PAYMENTERIO && (
                        <CustomButton
                            label="Blik, szybka płatność"
                            onClick={() => validateFields(onPaymenterioPressHandler, true)}
                            isDisabled={!isPaymenterioValid || isLoading}
                            className="flex flex-grow md:mr-2"
                        />
                    )}
                    {DEFAULT_PAYMENT_TYPE === PAYMENT_TYPES.DPAY && (
                        <CustomButton
                            label="Blik, szybka płatność"
                            onClick={() => validateFields(onDPayPressHandler, true)}
                            isDisabled={!isDPayValid || isLoading}
                            className="flex flex-grow md:mr-2"
                        />
                    )}
                    <CustomButton
                        label="DotBee"
                        onClick={() => validateFields(onProvemaPressHandler)}
                        isDisabled={!isProvemaValid || isLoading}
                        className="flex flex-grow md:ml-2"
                    />
                </div>
            </div>
        </>
    );
};

export default UserCode;
