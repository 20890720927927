const ROUTES = {
  HOME: "/",
  MAKE_PAYMENT: "/pay",
  ENTERPRISE: "/rejestracja",
  CONTACT: "/kontakt",
  PAYMENT_FINISHED: "/platnosc-wykonana",
  MAKE_PAYMENT_DEPRECATED: "/make-payment",
  PAYMENT_FINISHED_DEPRECATED: "/payment-finished",
  INFORMATION_CLAUSE: "/klauzula-informacyjna",
  AFFI: "/affi/:affi",
};

export default ROUTES;
