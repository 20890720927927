import {useState, useEffect} from "react";
import {Slide} from "react-awesome-reveal";

import DataSource, {APINames} from "../logic/DataSource";
import UserCode from "../components/MakePayment/UserCode";
import CompanyCode from "../components/MakePayment/CompanyCode";

const checkUserPlatform = (userAgent) => {
    let userPlatform = null;
    userPlatform = /Android/i.test(userAgent) ? "ANDROID" : userPlatform;
    userPlatform = /iPhone|iPad|iPod/i.test(userAgent) ? "IOS" : userPlatform;

    return userPlatform;
};

const MakePayment = () => {
    const dataSource = DataSource(APINames.TIP_TIP);
    const userPlatform = checkUserPlatform(navigator.userAgent);

    const [isCodeUuidError, setIsCodeUuidError] = useState(false);
    const [isShortCodeLoading, setIsShortCodeLoading] = useState(false);
    const [companyName, setCompanyName] = useState("");
    const [codeName, setCodeName] = useState("");
    const [codeUuid, setCodeUuid] = useState("");
    const [isCompanyCode, setIsCompanyCode] = useState(false);
    const [userCodes, setUserCodes] = useState(false);

    const short_code = window.location.pathname.split("/")[2] || null;

    const getCodeData = () => {
        setIsShortCodeLoading(true);
        dataSource.get({
            path: "/codes/" + short_code,
            onFinish: (res) => {
                if (res.data) {
                    setCompanyName(res.data.company_name);
                    setCodeName(res.data.code_name);
                    setCodeUuid(res.data.code_uuid);
                    setIsCompanyCode(res.data.code_type === 1 && res.data.user_codes?.length > 0)
                    setUserCodes(res.data.user_codes ?? [])
                    setIsCodeUuidError(res.data.company_id === 1);
                } else setIsCodeUuidError(true);
                setIsShortCodeLoading(false);
            },
            onError: () => {
                setIsCodeUuidError(true);
                setIsShortCodeLoading(false);
            },
        });
    };

    useEffect(() => {
        getCodeData();
    }, [short_code]);

    return (
        <div className="w-full flex flex-col items-center px-5 md:px-28 lg:px-64">
            {isShortCodeLoading ? null : (
                <Slide triggerOnce direction="left" className={`w-full ${!isCompanyCode ? 'xl:w-3/5' : ''} my-5`}>
                    <div className="pt-12 pb-6 px-10 shadow-xl rounded bg-white">
                        {isCodeUuidError ? (
                            <p className="text-gray text-lg lg:text-xl uppercase text-center mb-8">
                                Kod QR jest <span className="font-semibold">nieprawidłowy</span>, lub{" "}
                                <span className="font-semibold">nieaktualny {":("}</span>
                            </p>
                        ) : isCompanyCode ?
                            <CompanyCode
                                userCodes={userCodes}
                            />
                            :
                            <UserCode
                                userPlatform={userPlatform}
                                companyName={companyName}
                                codeName={codeName}
                                codeUuid={codeUuid}
                            />
                        }
                    </div>
                </Slide>
            )}
        </div>
    );
};

export default MakePayment;
