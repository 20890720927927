const PAYMENT_TYPES = {
  DOTPAY: "dotPay",
  PROVEMA: "provema",
  DPAY: "dPay",
  PAYMENTERIO: "paymenterio",
};

export const DEFAULT_PAYMENT_TYPE = PAYMENT_TYPES.PAYMENTERIO;

export default PAYMENT_TYPES;
