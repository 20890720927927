const CardStep = ({ icon = "", text = <p /> }) => {
  return (
    <div className="w-64 h-64 rounded-3xl bg-white shadow-xl pt-6 px-3 my-10 mx-8 flex flex-col items-center justify-center">
      {icon && <img src={icon} className="w-24 h-24" alt="icon" />}
      <p className="italic uppercase text-gray">{text}</p>
    </div>
  );
};

export default CardStep;
