import {APIes} from "../../consts/API";
import {Link} from "react-router-dom";
import ROUTES from "../../consts/ROUTES";

const CompanyCode = ({userCodes}) => {
        return (
            <>
                <div className="w-full xl:w-3/5 mx-auto">
                    <p className="text-gray text-lg lg:text-xl uppercase text-center mb-8">
                        PODZIĘKUJ ZA MIŁĄ OBSŁUGĘ <span className="font-semibold">BEZGOTÓWKOWYM</span> NAPIWKIEM {":)"}
                    </p>
                </div>
                <p className="text-gray text-lg lg:text-xl uppercase text-center mb-8">Wybierz osobę której chesz
                    podziękować:</p>
                <div className="flex flex-row flex-wrap justify-center">
                    {userCodes.map((code) => {
                        return (
                            <div key={code.code} className="w-full sm:w-1/2 sm:px-2 py-2">
                                <Link to={ROUTES.MAKE_PAYMENT + '/' + code.code}>
                                    <div className="w-full bg-orange-light py-5 px-3 flex flex-col items-center">
                                        <img
                                            src={`${APIes.tipTip}/codes/${code.code}/get-image-by-code`}
                                            className="rounded pb-2 w-2/3"
                                            alt="tip-receiver"
                                        />
                                        <p className="text-gray text-center pb-2">{code.company_name}</p>
                                        <p className="text-gray text-center pb-2">{code.code_name}</p>
                                    </div>
                                </Link>
                            </div>
                        )
                    })
                    }
                </div>
            </>
        );
    }
;

export default CompanyCode;
