import { Slide, Fade } from "react-awesome-reveal";

import Header from "../components/Home/Header";
import CardStep from "../components/Home/CardStep";
import CardPlace from "../components/Home/CardPlace";

import icon_receipt from "../assets/svg/icon_receipt.svg";
import icon_pick from "../assets/svg/icon_pick.svg";
import icon_finish from "../assets/svg/icon_finish.svg";
import restaurant from "../assets/img/restaurant.png";
import delivery from "../assets/img/delivery.png";
import shop from "../assets/img/shop.png";
import beauty from "../assets/img/beauty.png";

const Home = () => {
  return (
    <>
      <Fade triggerOnce>
        <Header />
      </Fade>
      <Fade triggerOnce>
        <div className="w-full flex flex-col justify-between items-center px-5 py-10 md:py-20 md:px-28 lg:px-64">
          <p className="text-gray text-l md:text-2xl lg:text-3xl text-justify">
            Czy zdarzyło Ci się, że byłeś <span className="font-bold">zadowolony</span> z obsługi kelnera, sprzedawcy
            czy dostawcy jedzenia i chętnie zostawiłbyś <span className="font-bold">napiwek</span>, ale nie miałeś przy
            sobie gotówki?
          </p>
          <p className="font-light text-orange text-5xl md:text-6xl lg:text-7xl fully-justify py-10">SHAKE TIPS</p>
          <p className="text-gray text-l md:text-2xl lg:text-3xl text-justify">
            to rozwiązanie, które pozwala szybko i <span className="font-bold">bezpiecznie</span> przekazać bezgotówkowy{" "}
            <span className="font-bold">napiwek</span>.
          </p>
        </div>
      </Fade>
      <Slide triggerOnce>
        <section
          id="jak-to-dziala"
          className="flex flex-col md:flex-row w-full items-center flex-wrap justify-center py-10 md:py-20 md:px-28 lg:px-64"
        >
          <CardStep
            icon={icon_receipt}
            text={
              <>
                <p className="text-center tracking-widest text-xl leading-tight">Do rachunku</p>
                <p className="text-center tracking-widest leading-tight">
                  otrzymasz <span className="font-extrabold">kod qr</span>
                </p>
                <p className="text-center tracking-widest text-xl leading-tight">pracownika</p>
                <p className="text-center tracking-widest text-xl leading-tight">
                  <span className="font-extrabold">-zeskanuj go</span>
                </p>
              </>
            }
          />
          <CardStep
            icon={icon_pick}
            text={
              <>
                <p className="text-center tracking-widest leading-3 pt-5">
                  <span className="font-extrabold">Wybierz</span> kwotę{" "}
                </p>
                <p className="text-center tracking-widest text-2xl">
                  <span className="font-extrabold">napiwku</span>
                </p>
              </>
            }
          />
          <CardStep
            icon={icon_finish}
            text={
              <>
                <p className="text-center leading-3 text-lg pt-5">Zrealizuj płatnością</p>
                <p className="text-center tracking-widest text-2xl">
                  <span className="font-extrabold">blik</span>
                </p>
              </>
            }
          />
        </section>
      </Slide>
      <div className="w-full flex flex-col justify-between items-center px-5 py-10 md:py-20 md:px-12 lg:px-48">
        <Fade triggerOnce>
          <p className="text-gray text-xl md:text-3xl lg:text-4xl tracking-widest text-center uppercase">
            <span className="font-bold">ShakeTips</span> możemy wykorzystać
          </p>
        </Fade>
        <Slide triggerOnce>
          <CardPlace
            label="w restauracji"
            description="To miejsce, które pierwsze przychodzi nam
            na myśl, kiedy mówimy o napiwkach. 
            Zadaniem kelnera jest zapewnienie
            profesjonalnej obsługi odwiedzanym klientom, 
            którzy poza doznaniami smakowymi, dużą wagę przywiązują do panującej atmosfery w lokalu.
            Dowodem uznania ze strony klienta, jest przekazanie napiwku.
            Za pomocą #ShakeTips brak gotówki nie przeszkodzi Ci już w jego przekazaniu."
            image={restaurant}
          />
        </Slide>
        <Slide triggerOnce direction="right">
          <CardPlace
            label="w salonie kosmetycznym"
            description="Miejsce do którego udajesz się, aby odpocząć, zrelaksować
            i zapomnieć 
            o codziennych rozterkach. To czas dla Ciebie, który zapewni Ci regenerację 
            oraz chwilę wytchnienia.
            Pracownicy salonu kosmetycznego dokonują wszelkich starań,  aby umilić Ci ten czas i zapewnić wyjątkowe doznania.
            Oprócz poprawy Twojej urody, kosmetyczki dbają także o zapewnienie 
            Ci doznań sensorycznych. Ich wysiłki mogą być przez Ciebie docenione
            za pomocą napiwków z #ShakeTips"
            image={beauty}
            left={false}
          />
        </Slide>
        <Slide triggerOnce>
          <CardPlace
            label="dla dostawcy jedzenia"
            description="To oni śpieszą do Ciebie z ulubionym jedzeniem.
            Ich widok wywołuje uśmiech, na twarzy każdego klienta.
            Niestety, praca dostawcy jedzenia jest często niedoceniana, a
            to dzięki ich zaangażowaniu i sprytowi przy omijaniu korków, 
            masz szansę zjedzenia ciepłego posiłku.
            Okaż im uznanie z #ShakeTips."
            image={delivery}
          />
        </Slide>
        <Slide triggerOnce direction="right">
          <CardPlace
            label="u sprzedawcy w sklepie"
            description="Bazary, targi, małe sklepiki. To miejsca, które muszą walczyć z dużą konkurencją wielkich galerii handlowych.
            Sprzedawcy zawsze starają się zaoferować Ci swoją pomoc, doradzając przy wyborze odpowiednich artykułów.
            Mimo określonych cen towarów, warto okazać im podziękowanie za ich zaangażowanie, przekazując napiwek z ShakeTips!"
            image={shop}
            left={false}
          />
        </Slide>
      </div>
    </>
  );
};

export default Home;
