import { useState, useMemo, useEffect } from "react";
import { FormControlLabel, InputAdornment } from "@material-ui/core";
import { Slide } from "react-awesome-reveal";
import ReCAPTCHA from "react-google-recaptcha";

import regulamin from "../../documents/Regulamin_Shake_Tips.pdf";

import DataSource, { APINames } from "../../logic/DataSource";
import Checkbox from "../Utility/Checkbox";
import CustomButton from "../Utility/Button";
import CustomTextField from "../Utility/TextField";
import { onlyDigitalAsString } from "../../logic/Common";

const Form = ({ setIsFinished = () => {} }) => {
  const dataSource = DataSource(APINames.TIP_TIP);

  const [companyPhone, setCompanyPhone] = useState("");
  const [companyPhoneError, setCompanyPhoneError] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [verifyCodeError, setVerifyCodeError] = useState("");
  const [showVerifyCodeField, setShowVerifyCodeField] = useState(false);
  const [agreementCheckbox, setAgreementCheckbox] = useState(false);
  const [regulationAgreementCheckbox, setRegulationAgreementCheckbox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [didUserResolveCaptcha, setDidUserResolveCaptcha] = useState(false);

  const isFormValid =
    companyPhone.length === 9 &&
    companyName.length > 0 &&
    didUserResolveCaptcha &&
    agreementCheckbox &&
    regulationAgreementCheckbox;

  const isCodeValid = verifyCode.length === 4;

  const affiToken = window.location.pathname.split("/")[2] || null;

  const handleSubmitResponseCodes = (code, status, message) => {
    if (status === 422) {
      if (message.company_name) setCompanyNameError("Podana firma już istnieje");
      if (message.phone_number) setCompanyPhoneError("Nieprawidłowy numer telefonu");
    }
    if (code === 1000) {
      setCompanyPhoneError("Posiadasz już aktywne konto");
      return;
    }
    if (status === 200) return setShowVerifyCodeField(true);
  };

  const handleSmsResponseCodes = (code, status, message) => {
    if (status === 422) setVerifyCodeError("Kod jest niepoprawny");

    if (status === 200) return setIsFinished(true);
  };

  const onVerifyCodeChange = (event) => {
    const value = onlyDigitalAsString(event.target.value);

    setVerifyCode(value);
  };

  const onCompanyNumberChange = (event) => {
    const value = onlyDigitalAsString(event.target.value);

    setCompanyPhone(value);
  };

  const onCompanyNameChange = (event) => {
    const value = event.target.value;

    setCompanyName(value);
  };

  const onSmsCodeVerifyHandler = () => {
    setIsLoading(true);

    dataSource.post({
      path: "/auth/check-verification-code",
      body: {
        phone_number: companyPhone,
        code: verifyCode,
      },
      onFinish: (res) => {
        setIsLoading(false);

        handleSmsResponseCodes(res.code, res.status, res.error);
      },
      onError: (err) => {
        setIsLoading(false);
      },
    });
  };

  const onSubmitHandler = () => {
    setIsLoading(true);

    const body = {
      phone_number: companyPhone,
      company_name: companyName,
    };

    const storagedAffiToken = sessionStorage.getItem("affiToken");

    if (affiToken !== null) body.affi_token = affiToken;
    else if (storagedAffiToken !== null) body.affi_token = storagedAffiToken;

    dataSource.post({
      path: "/auth/register",
      body: body,
      onFinish: (res) => {
        setIsLoading(false);

        handleSubmitResponseCodes(res.code, res.status, res.error);
      },
      onError: () => {
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    setCompanyNameError("");
    setCompanyPhoneError("");
    setVerifyCodeError("");
  }, [verifyCode, companyPhone, companyName]);

  useMemo(() => {
    if (affiToken !== null) {
      sessionStorage.setItem("affiToken", affiToken);
    }
  }, [affiToken]);

  return (
    <Slide triggerOnce direction="left" className="w-full xl:w-3/5 my-5">
      <div className="pt-12 pb-6 px-5 shadow-xl rounded xl:ml-10 bg-white">
        {showVerifyCodeField ? (
          <>
            <p className="text-gray text-lg lg:text-xl uppercase text-center mb-16">
              Wpisz <span className="font-semibold">kod SMS</span> wysłany na podany numer telefonu
            </p>
            <CustomTextField
              error={!!verifyCodeError}
              helperText={verifyCodeError}
              disabled={isLoading}
              inputProps={{ maxLength: 4 }}
              id="code"
              label="Kod sms"
              value={verifyCode}
              onChange={onVerifyCodeChange}
            />
            <CustomButton label="Potwierdź" isDisabled={!isCodeValid || isLoading} onClick={onSmsCodeVerifyHandler} />
          </>
        ) : (
          <>
            <p className="text-gray text-lg lg:text-xl uppercase text-center mb-16">
              <span className="font-semibold">Dołącz</span> do nas
            </p>
            <CustomTextField
              error={!!companyPhoneError}
              helperText={companyPhoneError}
              disabled={isLoading}
              InputProps={{
                startAdornment: <InputAdornment position="start">+48</InputAdornment>,
              }}
              inputProps={{ maxLength: 9 }}
              id="phone"
              label="Numer telefonu"
              value={companyPhone}
              onChange={onCompanyNumberChange}
            />
            <CustomTextField
              error={!!companyNameError}
              helperText={companyNameError}
              disabled={isLoading}
              inputProps={{ maxLength: 255 }}
              id="company_name"
              label="Nazwa firmy"
              type="text"
              value={companyName}
              onChange={onCompanyNameChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={isLoading}
                  checked={agreementCheckbox}
                  onChange={(event) => setAgreementCheckbox(event.target.checked)}
                />
              }
              label={
                <p className="text-gray font-display text-sm font-light">
                  Wyrażam zgodę na przetwarzanie moich danych zgodnie z ustawą o ochronie danych osobowych w związku z
                  wysłanym zapytaniem poprzez formularz kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do
                  przetworzenia zapytania. Zostałam/em poinformowany, że przysługuje mi prawo dostępu do moich danych,
                  możliwość ich poprawiana, żądania zaprzestania ich przetwarzania. Administratorem danych osobowych
                  jest Honey Payment sp. z o.o. (poprzednio The Great Ideas sp. z o.o.) z siedzibą w Warszawie (00-029)
                  przy ul. Nowy Świat, nr 33, lok. 13.
                </p>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={isLoading}
                  checked={regulationAgreementCheckbox}
                  onChange={(event) => setRegulationAgreementCheckbox(event.target.checked)}
                />
              }
              label={
                <p className="text-gray font-display text-sm font-light">
                  Zapoznałam/em się i akceptuję warunki{" "}
                  <a className="text-orange transition-all" href={regulamin}>
                    Regulaminu
                  </a>
                </p>
              }
            />
            <div className="flex justify-center pt-5">
              <ReCAPTCHA
                sitekey="6Lcs3mUeAAAAAGz6zvSDFXLmkqyrMTFi-a0Y-pWt"
                onChange={(isSuccess) => setDidUserResolveCaptcha(!!isSuccess)}
                onExpired={() => setDidUserResolveCaptcha(false)}
                onError={() => setDidUserResolveCaptcha(false)}
              />
            </div>
            <CustomButton label="Wyślij" isDisabled={!isFormValid || isLoading} onClick={onSubmitHandler} />
          </>
        )}
      </div>
    </Slide>
  );
};

export default Form;
