// import ReactPlayer from "react-player";

import header_phone from "../../assets/img/header_phone.png";
// import video from "../../assets/videos/video.mp4";

const Header = () => {
  return (
    <>
      <div className="w-full flex flex-col md:flex-row justify-between items-center px-5 py-5 md:py-20 md:px-28 lg:px-64">
        <div className="w-full md:w-3/4 py-5 md:pr-16">
          <p className="text-gray text-xl md:text-3xl lg:text-4xl text-center">
            BEZGOTÓWKOWE <span className="font-semibold">NAPIWKI</span>
          </p>
          <p className="font-light text-gray text-xl md:text-3xl lg:text-4xl text-center">
            Zobacz jakie to proste!
          </p>
          {/* <div className="w-full h-auto my-5">
            <ReactPlayer url={video} controls width="100%" height="100%" />
          </div> */}
        </div>
        <img
          src={header_phone}
          className="w-2/3 md:w-1/3 transform translate-x-10 md:translate-x-24"
          alt="phone"
        />
      </div>
    </>
  );
};

export default Header;
