import { NavLink, Link } from "react-router-dom";
import { useLocation } from "react-router";

import logoGif from "../../assets/gif/logo.gif";
/*
 gif takes noticable time to load
 put placeholder so the navbar doesnt jump
 after gif loaded
*/
import logoPlaceholder from "../../assets/img/logo_placeholder.png";
import ROUTES from "../../consts/ROUTES";
import {useState} from "react";
import NAV_LINKS from "../../consts/NAV_LINKS";

const Navbar = () => {
  const location = useLocation();
  const isMakePayment = location.pathname === ROUTES.MAKE_PAYMENT;
  const [navbar, setNavbar] = useState(false);

  const showNavbar = () => {
    setNavbar(true);
  };

  const hideNavbar = () => {
    setNavbar(false);
  }

  return (
    <nav className={`container mx-auto md:px-20 lg:px-60 md:py-6 flex flex-col ${isMakePayment ? "" : "md:flex-row md:justify-between"} items-center bg-white`}>
      <Link className="w-2/3 md:w-1/4 h-auto relative" to={ROUTES.HOME}>
        <img src={logoPlaceholder} width="100%" height="100%" alt="logo" />
        <img src={logoGif} className="absolute top-0 left-0" width="100%" height="100%" alt="logo" />
      </Link>
      {isMakePayment ? null : (
          <>
              <div onClick={showNavbar} className="space-y-2 fixed top-0 z-50 left-0 mx-2 my-2 md:hidden">
                  <span className="block w-8 h-0.5 bg-orange"></span>
                  <span className="block w-8 h-0.5 bg-orange"></span>
                  <span className="block w-5 h-0.5 bg-orange"></span>
              </div>
              <div className={`navbar-menu relative z-50 ${!navbar ? 'hidden' : ''}`}>
                  <div onClick={hideNavbar} className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
                  <nav
                      className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
                      <div className="flex items-center mb-8">
                          <div className="mr-auto">
                          </div>
                          <button onClick={hideNavbar} className="navbar-close">
                              <svg className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
                                   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                   stroke="currentColor">
                                  <path d="M6 18L18 6M6 6l12 12"></path>
                              </svg>
                          </button>
                      </div>
                      <div>
                          <ul>
                              {NAV_LINKS.map((link) => {
                                  return (
                                      <li className="mb-4" onClick={hideNavbar}>
                                          {link}
                                      </li>
                                  );
                              })}
                          </ul>
                      </div>
                  </nav>
              </div>
              <div className="flex flex-row justify-between w-full md:w-2/4 px-5 py-5 hidden md:block">
                  {NAV_LINKS.map((link) => {
                      return link;
                  })}
              </div>
          </>
      )}
    </nav>
  );
};

export default Navbar;
